document.addEventListener('DOMContentLoaded', (e) => {
	var timestamps = document.getElementsByClassName("time_converter");
	for (let timestamp of timestamps) {
		localeTimestamp = new Date(timestamp.innerHTML).toLocaleString(navigator.language, {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit',
	    hour: '2-digit',
	    minute:'2-digit'
	  });
    timestamp.innerHTML = localeTimestamp;
	}
});
